import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeRuExtra from '@angular/common/locales/extra/ru';

import localeRu from '@angular/common/locales/ru';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OriCommonModule } from './common/ori-common.module';
import { AuthService } from './services/auth.service';
import { BackendCallerService } from './services/backend-caller.service';
import { BackendService } from './services/backend.service';
import { DynamicConfigProviderService } from './services/dynamic-config-provider.service';
import { MoqBackendCallerService } from './services/moq-backend-caller.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { TranslocoRootModule } from './transloco-root.module';
import { preloadLang } from './preload-lang';


registerLocaleData(localeRu, 'ru-RU', localeRuExtra);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OriCommonModule,
    TranslocoRootModule,
  ],
  providers: [
    DynamicConfigProviderService,
    {
      provide: BackendService,
      useFactory: (http: HttpClient, configProvider: DynamicConfigProviderService) => {
        const useMockData = Boolean(JSON.parse(localStorage.getItem('use-mock-data')));
        return environment.useMockData || useMockData
          ? new MoqBackendCallerService(http, configProvider)
          : new BackendCallerService(http, configProvider);
      },
      deps: [HttpClient, DynamicConfigProviderService]
    },
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    preloadLang
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
