import { APP_INITIALIZER } from '@angular/core';
import { TranslocoService, getBrowserLang, Translation } from '@ngneat/transloco';
import { LocalStorageService } from 'angular-web-storage';
import { LangService } from './services/lang.service';


export function preLoadTranslations(transloco: TranslocoService, localStorage: LocalStorageService, langService: LangService) {
  return function() {
    let lang = transloco.getDefaultLang();
    let loadPromise: Promise<Translation> | null = null;
    const config = langService.loadConfig();
    if (config.hardcodedDefaultLang === true) {
      lang = config.defaultLang;
    } else {
      const localStorageLang = localStorage.get('locale');
      const browserLang = getBrowserLang();
      if (localStorageLang) {
        lang = localStorageLang;
      } else if (browserLang) {
        lang = browserLang;
      }
    }
    langService.setLocale(lang);
    loadPromise = transloco.load(lang).toPromise();
    return loadPromise;
  };
}

export const preloadLang = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: preLoadTranslations,
  deps: [TranslocoService, LocalStorageService, LangService]
};
