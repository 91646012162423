import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LocalStorageService } from 'angular-web-storage';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DynamicConfigProviderService } from './dynamic-config-provider.service';


export interface LangRuntimeConfig {
  hardcodedDefaultLang: boolean;
  defaultLang: string;
  showLangSelector: boolean;
}

const LOCALE_KEY = 'locale';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  langConfig: LangRuntimeConfig;
  loaded = false;

  constructor(private http: HttpClient,
              private transloco: TranslocoService,
              private configService: DynamicConfigProviderService,
              private localStorage: LocalStorageService) {}

  public loadConfig(): LangRuntimeConfig {
    const config = this.configService.getConfig();
    return {
      hardcodedDefaultLang: config.hardcodedDefaultLang,
      defaultLang: config.defaultLang,
      showLangSelector: config.showLangSelector
    } as LangRuntimeConfig;
  }



              public setLocale(lang_code) {
    this.transloco.setActiveLang(lang_code);
    this.localStorage.set(LOCALE_KEY, lang_code);
  }

  public getCurrentStorageLang() {
    this.localStorage.get(LOCALE_KEY);
  }

}
