import { Injectable } from '@angular/core';

export interface Config {
  apiUrl: string;
  wsUrl: string;
  storageUrl: string;
  hardcodedDefaultLang: boolean;
  defaultLang: string;
  showLangSelector: boolean;
}

interface ConfigModule {
  config: Config;
}

interface ConfigWindow extends ConfigModule, Window {
}

@Injectable({
  providedIn: 'root'
})
export class DynamicConfigProviderService {
  private config: Config;

  public constructor() {
    this.config = ((window as any) as ConfigWindow).config;
  }


  public getConfig(): Config {
      return this.config;
  }

  public get storageUrl(): string {
    return this.config.storageUrl;
  }

  public get backendUrl(): string {
    return this.config.apiUrl;
  }

  public get wsUrl(): string {
    return this.config.wsUrl;
  }
}
